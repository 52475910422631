import {
  IonContent,
  IonCard,
  IonInput,
  IonItem,
  IonButton,
  IonLoading,
  IonImg,
  useIonAlert,
  IonCardHeader,
  IonCardTitle,
} from "@ionic/react";
import { useHistory } from "react-router-dom";

import React, { useEffect } from "react";
import { useState } from "react";

/* Zustand imports */
import useStore from "../store";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { isLoggedIn, login, loginError } = useStore();
  const [presentAlert] = useIonAlert();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    // validate form and attempt login
    login(email, password).then(() => {
      setIsLoading(false);
      window.location.reload();
    });
  };

  return (
    <IonContent>
      <IonCard
        style={{ maxWidth: "300px", margin: "100px  auto", padding: "10px" }}
      >
        <IonImg src='/assets/logo.png' />

        <form onSubmit={handleSubmit}>
          <IonItem>
            <IonInput
              type='email'
              placeholder='Email'
              value={email}
              onIonChange={(event) => setEmail(event.target.value)}
              required
            />
          </IonItem>
          <IonItem>
            <IonInput
              type='password'
              placeholder='Password'
              value={password}
              onIonChange={(event) => setPassword(event.target.value)}
              required
            />
          </IonItem>
          <IonButton type='submit' expand='block'>
            Login
          </IonButton>
        </form>
        <IonLoading isOpen={isLoading} />
      </IonCard>
    </IonContent>
  );
};

export default Login;
