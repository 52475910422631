import { Redirect, Route } from "react-router-dom";
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLoading,
  IonNav,
} from "@ionic/react";
import { useState } from "react";
import { IonReactRouter } from "@ionic/react-router";
import {
  clipboard,
  person,
  addCircle,
  personCircleOutline,
  peopleOutline,
  albumsOutline,
} from "ionicons/icons";
import ClientPage from "../pages/ClientPage";
import AddDestination from "../pages/AddDestination";
import AddVisit from "../pages/AddVisit";
import Tab1 from "../pages/Tab1";
import Tab2 from "../pages/Tab2";
import Tab3 from "../pages/Tab3";
import Tab4 from "../pages/Tab4";
import '../serviceWorkerRegistration.ts'

const Main = () => {
  const [showLoadingfetch, setShowLoadingFetch] = useState(false);

  return (
    <>
      <IonReactRouter>
        <IonTabs animation="fade">
          <IonRouterOutlet>
            <Route exact path='/ordini'>
              <Tab1 />
            </Route>
            <Route exact path='/nuovoordine'>
              <Tab2 />
            </Route>
            <Route path='/profilo'>
              <Tab3 />
            </Route>
            <Route exact path='/clienti'>
              <Tab4 />
            </Route>
            <Route exact path='/clienti/:id' component={ClientPage} />
            <Route
              exact
              path='/creanuovadestinazione/:id'
              component={AddDestination}
            />
            <Route
              exact
              path='/creanuovavisita/:id'
              component={AddVisit}
            />
            <Route exact path='/'>
              <Redirect to='/nuovoordine' />
            </Route>
          </IonRouterOutlet>
          <IonTabBar slot='bottom'>
            <IonTabButton tab='contatti' href='/ordini'>
              <IonIcon icon={albumsOutline} />
              <IonLabel>Storico Ordini</IonLabel>
            </IonTabButton>
            <IonTabButton tab='ordini' href='/nuovoordine'>
              <IonIcon icon={addCircle} />
              <IonLabel>Nuovo Ordine</IonLabel>
            </IonTabButton>
            <IonTabButton tab='clienti' href='/clienti'>
              <IonIcon icon={peopleOutline} />
              <IonLabel>Clienti</IonLabel>
            </IonTabButton>
            <IonTabButton tab='profilo' href='/profilo'>
              <IonIcon icon={personCircleOutline} />
              <IonLabel>Profilo</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
      <IonLoading
        isOpen={showLoadingfetch}
        message={"Perfavore attendi, sto aggiornado i dati..."}
      />
    </>
  );
};

export default Main;
