import create from "zustand";
import axios from "axios";
import config from "../config";
import download from "downloadjs";
import { DateTime } from "luxon";

//Add a base URL to all requests made with Axios
axios.defaults.baseURL = config.baseUrl;
//limit the interceptor number of requests
let refreshTokenCounter = 0;

const refreshToken = () => {
  // Make a request to the token endpoint using the refresh token grant type
  return axios
    .post(
      "/authentication/oauth2/token",
      {
        grant_type: "refresh_token",
        refresh_token: localStorage.getItem("refresh_jwt"),
        client_id: `${process.env.REACT_APP_CLIENTID_KEY}`,
        client_secret: `${process.env.REACT_APP_SECRETID_KEY}`,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    .then((response) => {
      // If the request is successful, update the access and refresh tokens in local storage
      localStorage.setItem("jwt", response.data.access_token);
      // Reset the counter
      refreshTokenCounter = 0;
      localStorage.setItem("refresh_jwt", response.data.refresh_token);
      // Return the new access token
      return response.data.access_token;
    });
};

axios.interceptors.request.use((config) => {
  // Get the access token from local storage
  const accessToken = localStorage.getItem("jwt");
  // If there is a token, add it to the headers
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  // Return the config so that the request can be sent
  return config;
});

axios.interceptors.response.use(
  (response) => {
    // Return the response so that it can be handled by then
    return response;
  },
  (error) => {
    // If the error is a 401 Unauthorized error, try to refresh the token
    if (error.response.status === 401) {
      // Increment the counter
      console.log("scatta dentro iterceptor prima");
      refreshTokenCounter++;
      // If the counter is less than 3, try to refresh the token
      if (refreshTokenCounter < 30) {
        console.log("scatta dentro iterceptor dopo");
        return refreshToken().then((accessToken) => {
          // If the token refresh is successful, retry the original request
          error.config.headers["Authorization"] = `Bearer ${accessToken}`;
          return axios(error.config);
        });
      }
    }
    // If the token refresh fails or the error is not a 401, or if the counter is 3 or more, throw the error
    throw error;
  }
);

const useStore = create((set) => ({
  user: [
    {
      name: "",
      email: "",
      phone_number: "",
    },
  ],
  isLoggedIn: !!localStorage.getItem("jwt"),
  partners: [],
  products: [],
  loginError: false,
  saleorders: [],
  categories: [],
  order_tracking: [],
  orderProductLines: [
    {
      id: "",
      name: "",
      product_qty: "",
      qty_delivered: "",
    },
  ],
  saleorderInfo: [
    {
      partner_id: [0, ""],
      partner_invoice_id: [0, ""], // va sistemato
      partner_shipping_id: [0, ""],
      type_name: "",
      invoice_status: "",
      note_da_app: false,
      amaunt_total: "",
      delivery_note_ids: [],
      commission_total: "",
      write_uid: [],
      user_id: [],
      products: [],
      tag_ids: [],
      state:"",
    },
  ],
  clientMeetings: [{}],
  axiosloading: false,
  fetchProducts: async () => {
    try {
      const response = await axios.get("/search_read/product.template", {
        params: {
          fields:
            '["name","sale_ok","categ_id","default_code","qty_available"]',
        },
      });
      console.log("questo avviene nello store", response.data);
      localStorage.setItem("products", response.data);
      set((state) => ({ products: response.data }));
    } catch (error) {
      console.log(error);
    }
  },
  fetchPartners: async () => {
    try {
      const response = await axios.get("/search_read/res.partner", {
        params: {
          fields:
            '["name","is_company","parent_id","child_ids","street","street2","zip","city","property_product_pricelist","property_payment_term_id","meeting_ids"]',
        },
      });
      console.log("questo avviene nello store partner", response.data);

      set((state) => ({ partners: response.data }));
      console.log("xxxxxxxxxxxxxxxxxxxxx", useStore.partners);
    } catch (error) {
      console.log(error);
    }
  },
  fetchOrders: async () => {
    try {
      const response = await axios.get("/search_read/sale.order", {
        params: {
          fields:
            '["id","name","partner_invoice_id","create_date","create_date","amount_total","tag_ids","commission_total","partner_shipping_id","order_line","picking_ids","state"]',
        },
      });
      console.log("saleorderrrr", response.data);
      set((state) => ({ saleorders: response.data }));
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  fetchTraking: async (id) => {
    try {
      const response = await axios.get("/read/stock.picking", {
        params: {
          ids: `[${id}]`,
          fields: '["carrier_tracking_ref", "carrier_id"]',
        },
      });
      console.log("tracking info", response.data);
      set((state) => ({ order_tracking: response.data }));
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  fetchOrderInfo: async (id) => {
    try {
      const response = await axios.get("/read/sale.order", {
        params: {
          ids: `["${id}"]`,
        },
      });
      console.log("infoooooo", response.data);
      set((state) => ({ saleorderInfo: response.data }));
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  fetchCategories: async () => {
    try {
      const response = await axios.get("/search_read/product.category", {
        params: {},
      });
      console.log("categories store", response.data);
      set((state) => ({ categories: response.data }));
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  fetchUserInfo: async () => {
    try {
      const response = await axios.get("/session", {
        params: {},
      });
      console.log("User info", response.data);
      set((state) => ({ user: response.data }));
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  login: async (username, password) => {
    try {
      const url = "/authentication/oauth2/token";

      const auth = {
        username: `${process.env.REACT_APP_CLIENTID_KEY}`,
        password: `${process.env.REACT_APP_SECRETID_KEY}`,
      };

      const options = {
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: `grant_type=password&username=${username}&password=${password}`,
        auth: auth,
        url,
      };

      const response = await axios(options);
      const token = `Bearer ${response.data.access_token}`;
      const { data } = response;
      console.log("tokeeeeeen", response.data);
      localStorage.setItem("jwt", data.access_token);
      localStorage.setItem("refresh_jwt", data.refresh_token);
      localStorage.setItem("isLoggedIn", true);
      set((state) => ({ isLoggedIn: !!localStorage.getItem("jwt") }));
    } catch (error) {
      console.log(error);
      alert(
        "Errore di autenticazione, se l'errore persiste contattare bioservice a info@bioservicesrl.eu"
      );
    }
  },
  logout: () => {
    localStorage.clear();
    set((state) => ({ user: null, isLoggedIn: false }));
  },
  inviaAnagrafica: (email, Apikeysendinblue) => {
    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Bioservice Srl",
            email: `${email.mittetente}`,
          },
          to: [
            {
              email: `${email.destinario}`,
              name: "Anouar El Hachimi",
            },
          ],
          subject: `${email.oggetto}`,
          htmlContent: `${email.contenuto}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": `${Apikeysendinblue}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  downloadDDT: async (ids, orderName) => {
    if (ids.length === 0) {
      return; // exit the function without doing anything
    }

    console.log("jygjjhg", ids);
    try {
      const response = await axios.get("/report", {
        params: {
          report: "l10n_it_delivery_note.delivery_note_report_main_template",
          ids: `${ids}`,
          type: "PDF",
          file_response: "true",
        },
        responseType: "blob", // set the responseType to "blob"
      });

      console.log(response.headers);
      download(response.data, `${orderName}`, "application/pdf");
      console.log("report info", response);
      return response.data;
    } catch (error) {
      console.error(`Error downloading DDT: ${{ error }}`);
    }
  },
  updatePrice: async (id) => {
    try {
      const response = await axios.post("/call/sale.order/update_prices", {
        ids: `["${id}"]`,
      });
      console.log("update prices", response);
    } catch (error) {
      console.error(`Error: ${{ error }}`);
    }
  },
  fetchOrderLines: async (ids) => {
    try {
      const response = await axios.get("/read/sale.order.line", {
        params: {
          ids: `[${ids}]`,
          fields: '["name","product_qty","qty_delivered"]',
        },
      });
      console.log("lines", response.data);
      set((state) => ({ orderProductLines: response.data }));
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  createVisit: async (partnerId, agentId, visit) => {
    const date = new Date(visit.data);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = "08";
    const minutes = "00";
    const seconds = "00";

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    console.log(formattedDate); // Output: 2023-03-09 10:00:00

    try {
      const response = await axios.post("/create/calendar.event", {
        values: `[
          {
              "name": "${visit.oggetto}",
              "start": "${formattedDate}",
              "stop": "${formattedDate}",
              "description":"${visit.note}",
              "partner_ids": [[4,${partnerId}],[4,${agentId}]],
              "allday":"true"
          }
      ]`,
      });
      console.log("calendar", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  fetchMeetings: async (ids) => {
    try {
      const response = await axios.get("/read/calendar.event", {
        params: {
          ids: `[${ids}]`,
        },
      });
      console.log("meetings", response.data);
      set((state) => ({ clientMeetings: response.data }));
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  sendOrderConfirm: async (email, senderName, client, order) => {
    try {
      const now = DateTime.local().setLocale("it");
      const response = await axios.post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Bioservice Srl",
            email: "info@bioservicesrl.eu",
          },
          to: [
            {
              email: "info@bioservicesrl.eu",
              name: `${senderName}`,
            },
          ],
          cc: [
            {
              email: `${email}`,
            },
          ],
          subject: `Conferma ordine ${client}, ${order.sped_name}`,
          htmlContent: `
          <html>
            <head></head>
            <body>
              <p><b>Conferma dell'ordine a ${client}, ${
            order.sped_name
          } </b></p>
              <p><b>Agente:</b>${senderName}</p>
              <p><b>Data e ora :</b>${now.toFormat(
                "EEEE, dd MMMM',' yyyy 'alle' HH:mm"
              )}</p>
              <h3>Riepilogo</h3>
              <p><b>Fatturato a:</b>${order.fatt_name} </p>
              <p><b>Fndirizzo di fatturazione:</b> ${order.fatt_street}, ${
            order.fatt_city
          }, ${order.fatt_zip}
               </p>
              <p><b>Spedito a:</b> ${order.sped_name}, ${order.sped_street}, ${
            order.sped_city
          }, ${order.sped_zip}
               </p>
              <h3>Prodotti ordinati:</h3>
              <ul>
                ${order.product_lines
                  .map(
                    (product) =>
                      `<li><b>${product.default_code}</b>, ${product.name}, Quantità: ${product.quantity}</li>`
                  )
                  .join("")}
              </ul>
            </body>
          </html>
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": `${process.env.REACT_APP_SENDINBLUE_KEY}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  },
}));

export default useStore;
