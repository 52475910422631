import { Redirect, Route } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  IonLoading,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ellipse, square, triangle } from "ionicons/icons";
import Tab1 from "./pages/Tab1";
import Tab2 from "./pages/Tab2";
import Tab3 from "./pages/Tab3";
import Main from "./components/Main";
import Login from "./components/Login";
import { AuthContext } from "./authContext";
import { APP_VERSION } from "./config";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Zustand imports */
import useStore from "./store";

setupIonicReact();

const App = () => {
  const {
    isLoggedIn,
    login,
    logout,
    products,
    fetchProducts,
    fetchOrders,
    fetchPartners,
    partners,
    categories,
    fetchCategories,
    fetchUserInfo,
  } = useStore();
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  // Check if the current app version is different from the one in local storage
  const storedVersion = localStorage.getItem("appVersion");
  if (storedVersion === null || storedVersion === undefined) {
    // No stored version found, assume the user is running the latest version
    localStorage.setItem("appVersion", APP_VERSION);
  } else if (storedVersion !== APP_VERSION) {
    // Clear the local storage and store the current app version
    localStorage.clear();
    localStorage.setItem("appVersion", APP_VERSION);
    window.location.reload();
  }

  useEffect(() => {
    async function fetchData() {
      try {
        await Promise.all([
          fetchProducts(),
          fetchCategories(),
          fetchPartners(),
          fetchOrders(),
          fetchUserInfo(),
        ]).then(() => setLoading(false));
      } catch (error) {
        console.error(error);
      }
    }

    if (isLoggedIn) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [isLoggedIn]);

  return (
    <>
      <IonApp>
        {loading ? (
          <IonLoading
            isOpen={loading}
            message={"Sto recuperando le informazioni dai server bioservice..."}
          />
        ) : (
          <IonRouterOutlet>
            {!isLoggedIn ? <Login /> : <Main />}
          </IonRouterOutlet>
        )}
      </IonApp>
    </>
  );
};

export default App;
