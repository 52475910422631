import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonList,
  IonLabel,
  IonItemDivider,
  IonItemGroup,
  IonItem,
  IonRow,
  IonGrid,
  IonCol,
  IonMenu,
  IonMenuButton,
  IonButtons,
  IonMenuToggle,
  IonModal,
  IonInput,
  IonLoading,
  useIonToast,
  IonNote,
  IonSearchbar,
  IonFabButton,
  IonSegmentButton,
  IonSegment,
  IonIcon,
  IonFab,
  IonFabList,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import {
  addCircleOutline,
  chevronUpCircle,
  ellipsisHorizontalOutline,
  colorPalette,
  add,
  globe,
  navigateCircleOutline
} from "ionicons/icons";
import { logOut } from "ionicons/icons";
import ExploreContainer from "../components/ExploreContainer";
import { APP_VERSION } from "../config";
import "./ClientPage.css";
import "./AddDestination";
import useStore from "../store";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { userInfo } from "os";
import { Virtuoso } from "react-virtuoso";

const ClientPage: React.FC = ({ match }: any) => {
  const { logout, user, partners, updateEvent, fetchMeetings, clientMeetings } =
    useStore();
  const [showLoadingOrder, setShowLoadingOrder] = useState(false);
  const [currentSegment, setCurrentSegment] = useState("visite");
  const [present] = useIonToast();
  partners.filter((partner: any) => partner.id === match.params.id);
  const [meeting, setMeeting] = useState([]);

  const presentToast = (position: "top" | "middle" | "bottom") => {
    present({
      message: "Prodotto Aggiunto!",
      duration: 200,
      position: position,
    });
  };

  const client = partners.find(
    (partner: { id: string }) => String(partner.id) === match.params.id
  );

  const navigateToLocation = (address:any, city:any) => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${address},${city}`;
    window.open(url, '_system');
  };

  useEffect(() => {
    setShowLoadingOrder(true);
    fetchMeetings(client.meeting_ids).then(() => {
      setShowLoadingOrder(false);
      console.log(clientMeetings);
    });
  }, []);

  const RenderDestinations = () => {
    // Filter the partner array to only include destinations with an ID matching the child ID
    const destinations = partners.filter((item: any) =>
      client.child_ids.includes(item.id)
    );

    // Check if any matching destinations were found
    if (destinations.length === 0) {
      return (
        <IonItem>
          <IonLabel>Nessuna destinazione aggiuntiva</IonLabel>
        </IonItem>
      );
    }
    // Map each matching destination to an IonItem component with an IonLabel containing the destination name
    return (
      <Virtuoso
        style={{ height: "100%" }}
        data={destinations}
        itemContent={(index, destinations) => (
          <IonItem>
            <IonLabel>
              <h2 style={{ whiteSpace: 'normal' }}>{destinations.name}</h2>
              <p style={{ whiteSpace: 'normal' }}>
                {destinations.street}, {destinations.city} , {destinations.zip}
              </p>
            </IonLabel>
            <IonButton fill="clear" size="large" onClick={() => navigateToLocation(destinations.street, destinations.city)}>
              <IonIcon icon={navigateCircleOutline} slot='end'></IonIcon>
            </IonButton>
          </IonItem>
        )}
      />
    );
  };

  const RenderVisits = () => {
    return (
      <>
        <IonItem>
          <IonLabel>niente per ora</IonLabel>
        </IonItem>

        <Virtuoso
          style={{ height: "100%" }}
          data={clientMeetings}
          itemContent={(index, clientMeetings) => (
            <IonItem>
              <IonLabel class='ion-text-wrap'>
                <h2>{clientMeetings.display_name}</h2>
                <p>{clientMeetings.display_time}</p>
                <p>
                  {clientMeetings.description
                    ? clientMeetings.description
                    : "nessun dettaglio aggiuntivo"}
                </p>
              </IonLabel>
            </IonItem>
          )}
        />
      </>
    );
  };

  const handleSegmentChange = (event: any) => {
    setCurrentSegment(event.detail.value);
  };

  function handleRefresh(event: CustomEvent) {
    setTimeout(() => {
      fetchMeetings(client.meeting_ids);
      present({
        message: "Aggiornato",
        duration: 1300,
        position: "top",
      });
      event.detail.complete();
    }, 2000);
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Cliente n°{match.params.id}</IonTitle>
            <IonButtons slot='start'>
              <IonButton routerLink='/clienti' routerDirection='back'>
                Indietro
              </IonButton>
            </IonButtons>
            <IonButtons slot='end'>
              <IonButton>
                <IonIcon icon={ellipsisHorizontalOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel >Cliente:</IonLabel>
            </IonItemDivider>
            <IonItem lines='full'>
              <IonLabel>
                <h2 style={{ whiteSpace: 'normal' }}>{client.name}</h2>
                <p style={{ whiteSpace: 'normal' }}>
                  {client.street}, {client.city} , {client.zip}s
                </p>
              </IonLabel>
              <IonButton fill="clear" size="large" onClick={() => navigateToLocation(client.street, client.city)}>
              <IonIcon icon={navigateCircleOutline} slot='end'></IonIcon>
              </IonButton>
            </IonItem>
          </IonItemGroup>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Pagamento Preferito:</IonLabel>
            </IonItemDivider>
            <IonItem lines='full'>
              <IonLabel>{client.property_payment_term_id[1]}</IonLabel>
            </IonItem>
          </IonItemGroup>
          <IonSegment
            color='primary'
            value={currentSegment}
            onIonChange={handleSegmentChange}
          >
            <IonSegmentButton value='visite'>
              <IonLabel>Visite</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value='destinazioni'>
              <IonLabel>Destinazioni</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          {currentSegment === "destinazioni" ? (
            <>
              <IonButton
                expand='block'
                routerLink={`/creanuovadestinazione/${match.params.id}`}
                routerDirection='forward'
              >
                Aggiungi Destinazione
              </IonButton>
              <RenderDestinations />
            </>
          ) : (
            <>
              <IonButton
                disabled
                expand='block'
                routerLink={`/creanuovavisita/${match.params.id}`}
                routerDirection='forward'
              >
                Aggiungi Visita
              </IonButton>
              <RenderVisits />
            </>
          )}
        </IonContent>
      </IonPage>
      <IonLoading
        isOpen={showLoadingOrder}
        message={"Perfavore attendi, sto recuperando le informazioni..."}
      />
    </>
  );
};

export default ClientPage;
