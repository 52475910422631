import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonFooter,
  IonTitle,
  IonPage,
  IonItem,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonLoading,
  IonModal,
  IonItemGroup,
  IonItemDivider,
  IonSearchbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonChip,
  IonText,
  IonRouterLink,
} from "@ionic/react";
import React from "react";
import { DateTime } from "luxon";
import { Settings } from "luxon";
import axios from "axios";
import "./Tab1.css";
import useStore from "../store";
import { useEffect, useState } from "react";
import { cart } from "ionicons/icons";
import { Virtuoso } from "react-virtuoso";
import Fuse from "fuse.js";

const Tab1: any = () => {
  const {
    fetchOrders,
    saleorders,
    partners,
    products,
    fetchOrderInfo,
    saleorderInfo,
    fetchProducts,
    fetchPartners,
    fetchCategories,
    fetchOrderLines,
    downloadDDT,
    orderProductLines,
    fetchTraking,
    order_tracking,
  } = useStore();
  const [showLoadingfetch, setShowLoadingFetch] = useState(false);
  const [showLoadingDDT, setShowLoadingDDT] = useState(false);
  const [showModal, setShowModal] = useState(false);
  let [results, setResults] = useState([...saleorders]);

  function handleRefresh(event: CustomEvent) {
    setTimeout(() => {
      fetchOrders();
      event.detail.complete();
    }, 2000);
  }

  //riceve in ingresso il termine di ricerca e restituisce un array filtrato
  const handleChange = (ev: Event) => {
    const fuse = new Fuse(saleorders, {
      keys: ["partner_invoice_id", "partner_shipping_id", "name"],
      threshold: 0.3,
    });
    const target = ev.target as HTMLIonSearchbarElement;
    const query = target.value?.trim().toLowerCase() || "";
    if (query === "") {
      setResults([...saleorders]);
    } else {
      const searchResults = fuse.search(query);
      const items = searchResults.map((r) => r.item);
      setResults(items);
    }
  };

  useEffect(() => {
    //setShowLoadingFetch(true);
    const token = localStorage.getItem("jwt");
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    //fetchCategories();
    /*fetchOrders().then(() => {
      setShowLoadingFetch(false);
    });*/
    setResults([...saleorders]);
  }, []);

  useEffect(() => {
    setResults([...saleorders]);
  }, [saleorders]);

  useEffect(() => {
    if (partners.length === 0) {
      setShowLoadingFetch(true);
      fetchPartners().then(() => {
        setShowLoadingFetch(false);
      });
    }
  }, []);

  useEffect(() => {
    if (products.length === 0) {
      setShowLoadingFetch(true);
      fetchProducts().then(() => {
        setShowLoadingFetch(false);
      });
    }
  }, []);

  const openModal = (id: any, picking_id: any) => {
    setShowLoadingFetch(true);

    Promise.all([
      fetchTraking(picking_id),
      fetchOrderInfo(id),
      fetchOrderLines(
        saleorders.find((order: any) => order.id === id).order_line
      ),
    ]).then(() => {
      setShowLoadingFetch(false);
      console.log("prdotti", orderProductLines);
    });
    setShowModal(true);
  };

  const RequestDDT = (ids: any, orderName: any) => {
    setShowLoadingDDT(true);
    downloadDDT(ids, orderName).then(() => {
      setShowLoadingDDT(false);
    });
  };

  function convertDateToItalyTime(serverDate: string) {
    // Modify serverDate string to match ISO datetime format
    const isoDate = serverDate.replace(" ", "T") + "Z";
    // Set language settings to Italian
    Settings.defaultLocale = "it";
    const utcDateTime = DateTime.fromISO(isoDate, { zone: "utc" });
    const italyDateTime = utcDateTime.setZone("Europe/Rome");
    const italyTime = italyDateTime.toFormat(
      "EEEE, dd MMMM',' yyyy 'alle' HH:mm"
    );
    return italyTime;
  }

  function copyToClipboard(text: any) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Ordini di vendita</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonSearchbar
        animated={true}
        placeholder='Cliente'
        onIonChange={(ev) => handleChange(ev)}
      ></IonSearchbar>
      <IonContent>
        <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <Virtuoso
          data={results
            .slice(0, 50)
            .sort((a, b) => b.name.localeCompare(a.name))}
          itemContent={(index, item) => {
            try {
              return (
                <IonItem
                  button
                  detail={true}
                  key={item.id}
                  onClick={() => openModal(item.id, item.picking_ids)}
                >
                  <IonLabel>
                    <h2>{item.name}</h2>
                    <p style={{ whiteSpace: 'normal' }}><strong>Fatt:</strong> {item.partner_invoice_id[1]}</p>
                    <p style={{ whiteSpace: 'normal' }} ><strong>Sped:</strong> {item.partner_shipping_id[1]}</p>
                    <p>{convertDateToItalyTime(item.create_date)}</p>
                    {item.state === "draft" && !item.tag_ids.includes(1) && (
                      <IonChip color='warning'>In preparazione</IonChip>
                    )}
                    {item.state !== "draft" && !item.tag_ids.includes(1) && (
                      <IonChip color='success'>Spedito</IonChip>
                    )}
                    {item.tag_ids && item.tag_ids.includes(1) && (
                      <IonChip color='danger'>Alta priorità</IonChip>
                    )}
                  </IonLabel>
                </IonItem>
              );
            } catch (error) {
              console.error("Error rendering item:", error);
              return (
                <IonItem key={item.id}>
                  <IonLabel>Error rendering item</IonLabel>
                </IonItem>
              );
            }
          }}
        />
      </IonContent>
      <IonModal
        onDidDismiss={() => setShowModal(false)}
        backdrop-dismiss={true}
        isOpen={showModal}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle></IonTitle>
            <IonButtons slot='start'>
              <IonButton onClick={() => setShowModal(false)}>
                Indietro
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Numero d'ordine:</IonLabel>
            </IonItemDivider>
            <IonItem key={saleorderInfo[0].id}>
              <IonLabel>{saleorderInfo[0].name}</IonLabel>
            </IonItem>
          </IonItemGroup>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Creato da:</IonLabel>
            </IonItemDivider>
            <IonItem key={saleorderInfo[0].id}>
              <IonLabel>{saleorderInfo[0].user_id[1]}</IonLabel>
            </IonItem>
          </IonItemGroup>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Fatturato a:</IonLabel>
            </IonItemDivider>
            {saleorderInfo[0]?.partner_invoice_id?.[0] &&
              partners.find(
                (p: any) => p.id === saleorderInfo[0]?.partner_invoice_id?.[0]
              ) && (
                <IonItem key={saleorderInfo[0].id}>
                  <IonLabel>
                    <h2>
                      {partners.find(
                        (p: any) =>
                          p.id === saleorderInfo[0]?.partner_invoice_id?.[0]
                      )?.name ?? "assente o archiviato"}
                    </h2>
                    <p>
                      {partners.find(
                        (p: any) =>
                          p.id === saleorderInfo[0]?.partner_invoice_id?.[0]
                      )?.street ?? "assente o archiviato"}
                      ,{" "}
                      {partners.find(
                        (p: any) =>
                          p.id === saleorderInfo[0]?.partner_invoice_id?.[0]
                      )?.city ?? "assente o archiviato"}
                      ,{" "}
                      {partners.find(
                        (p: any) =>
                          p.id === saleorderInfo[0]?.partner_invoice_id?.[0]
                      )?.zip ?? "assente o archiviato"}
                    </p>
                  </IonLabel>
                </IonItem>
              )}
          </IonItemGroup>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Spedito a:</IonLabel>
            </IonItemDivider>
            {saleorderInfo[0]?.partner_invoice_id?.[0] &&
              partners.find(
                (p: any) => p.id === saleorderInfo[0]?.partner_invoice_id?.[0]
              ) && (
                <IonItem key={saleorderInfo[0].id}>
                  <IonLabel>
                    <h2>
                      {partners.find(
                        (p: any) =>
                          p.id === saleorderInfo[0]?.partner_shipping_id?.[0]
                      )?.name ?? "assente o archiviato"}
                    </h2>
                    <p>
                      {partners.find(
                        (p: any) =>
                          p.id === saleorderInfo[0]?.partner_shipping_id?.[0]
                      )?.street ?? "assente o archiviato"}
                      ,{" "}
                      {partners.find(
                        (p: any) =>
                          p.id === saleorderInfo[0]?.partner_shipping_id?.[0]
                      )?.city ?? "assente o archiviato"}
                      ,{" "}
                      {partners.find(
                        (p: any) =>
                          p.id === saleorderInfo[0]?.partner_shipping_id?.[0]
                      )?.zip ?? "assente o archiviato"}
                    </p>
                  </IonLabel>
                </IonItem>
              )}
          </IonItemGroup>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Stato:</IonLabel>
            </IonItemDivider>
            <IonItem key={saleorderInfo[0].id}>
              <IonLabel>
                {saleorderInfo[0].type_name === "Preventivo"
                  ? "Ordine Arrivato e in preparazione"
                  : "Ordine Spedito"}
              </IonLabel>
            </IonItem>
          </IonItemGroup>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>fatturato:</IonLabel>
            </IonItemDivider>
            <IonItem key={saleorderInfo[0].id}>
              <IonLabel>{saleorderInfo[0].invoice_status}</IonLabel>
            </IonItem>
          </IonItemGroup>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Note:</IonLabel>
            </IonItemDivider>
            <IonItem key={saleorderInfo[0].id}>
              <IonLabel>
                {saleorderInfo[0].note_da_app
                  ? saleorderInfo[0].note_da_app
                  : "nessuna nota inoltrata"}
              </IonLabel>
            </IonItem>
          </IonItemGroup>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Prodotti:</IonLabel>
            </IonItemDivider>
            {orderProductLines.map((item: any) => (
              <IonItem key={item.id}>
                <IonLabel>
                  <h2>{item.name}</h2>
                  <p>Spediti: {item.qty_delivered}</p>
                </IonLabel>
                <IonLabel slot='end' color='warning'>
                  {item.product_qty}
                </IonLabel>
                <IonIcon icon={cart} slot='end'></IonIcon>
              </IonItem>
            ))}
          </IonItemGroup>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>importo totale:</IonLabel>
            </IonItemDivider>
            <IonItem key={saleorderInfo[0].id}>
              <IonLabel>{saleorderInfo[0].amount_total}€</IonLabel>
            </IonItem>
          </IonItemGroup>

          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Link di Tracking:</IonLabel>
            </IonItemDivider>

            {order_tracking && order_tracking.length > 0 ? (
              <>
                <IonItem>
                  <IonLabel>
                    Vettore: {order_tracking[0].carrier_id[1]}
                  </IonLabel>
                </IonItem>

                <IonItem>
                  {order_tracking[0].carrier_tracking_ref ? (
                    <>
                    <IonLabel>
                      <a
                        href={`https://gls-group.com/IT/it/servizi-online/ricerca-spedizioni.html?match=${order_tracking[0].carrier_tracking_ref}&type=NAT`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {order_tracking[0].carrier_tracking_ref}
                      </a>
                    </IonLabel>
                    <IonButton
                    slot='end'
                    onClick={() =>
                      navigator.clipboard.writeText(
                        `https://gls-group.com/IT/it/servizi-online/ricerca-spedizioni.html?match=${order_tracking[0].carrier_tracking_ref}&type=NAT`
                      )
                    }
                  >
                    Copia il link
                  </IonButton>
                  </>
                  ) : (
                    <IonLabel>Non ancora disponibile</IonLabel>
                  )}

                  
                </IonItem>
              </>
            ) : (
              <IonItem>
                <IonLabel>
                  Nessun prelievo in magazzino per quest'ordine.
                </IonLabel>
              </IonItem>
            )}
          </IonItemGroup>

          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>commissioni:</IonLabel>
            </IonItemDivider>
            <IonItem key={saleorderInfo[0].id}>
              <IonLabel>{saleorderInfo[0].commission_total}€</IonLabel>
            </IonItem>
          </IonItemGroup>

          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>DDT:</IonLabel>
            </IonItemDivider>
            <IonItem
              button={saleorderInfo[0].delivery_note_ids.length > 0}
              detail={true}
              onClick={() =>
                RequestDDT(
                  saleorderInfo[0].delivery_note_ids,
                  saleorderInfo[0].name
                )
              }
            >
              <IonLabel>
                {saleorderInfo[0].delivery_note_ids.length === 0
                  ? "Non ancora emesso"
                  : "Scaricalo ora"}
              </IonLabel>
            </IonItem>
          </IonItemGroup>
        </IonContent>

        <IonFooter>
          <IonToolbar>
            <IonButton
              expand='block'
              fill='outline'
              onClick={() => setShowModal(false)}
            >
              Chiudi
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
      <IonLoading
        isOpen={showLoadingfetch}
        message={"Perfavore attendi, sto aggiornado i dati..."}
      />
      <IonLoading
        isOpen={showLoadingDDT}
        message={"Perfavore attendi, sto generando il DDT..."}
      />
    </IonPage>
  );
};

export default Tab1;
