import {
  IonItem,
  IonList,
  IonAccordion,
  IonLabel,
  IonAccordionGroup,
  IonButton,
  useIonActionSheet,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import type { OverlayEventDetail } from "@ionic/core";
import React, { useState } from "react";
interface CategoryListProps {
  categories: any[];
  onSelectCategory: (categoryId: number) => void;
}

const CategoryList: React.FC<CategoryListProps> = ({
  categories,
  onSelectCategory,
}) => {
  const [present] = useIonActionSheet();
  const [result, setResult] = useState<OverlayEventDetail>();
  return (
    <>
      <div className='container'>
        <IonButton
          onClick={() =>
            present({
              header: "Scegli un impianto",
              buttons: [
                {
                  text: "Delete",
                  role: "destructive",
                  data: {
                    action: "delete",
                  },
                },
                {
                  text: "Share",
                  data: {
                    action: "share",
                  },
                },
                {
                  text: "Cancel",
                  role: "cancel",
                  data: {
                    action: "cancel",
                  },
                },
              ],
              onDidDismiss: ({ detail }) => setResult(detail),
            })
          }
        >
          Open
        </IonButton>
        {result && <code>{JSON.stringify(result, null, 2)}</code>}
      </div>
      <IonList>
        {categories
          .filter((category: any) => category.parent_id === false)
          .map((category: any) => (
            <IonItem
              key={category.id}
              onClick={() => onSelectCategory(category.id)}
            >
              {category.display_name}
            </IonItem>
          ))}
      </IonList>
      <IonAccordionGroup>
        <IonAccordion value='first'>
          <IonItem slot='header' color='light'>
            <IonLabel>First Accordion</IonLabel>
          </IonItem>

          <IonItem>
            <div slot='content'>
              <IonSelect placeholder='Select fruit'>
                <IonSelectOption value='apples'>Apples</IonSelectOption>
                <IonSelectOption value='oranges'>Oranges</IonSelectOption>
                <IonSelectOption value='bananas'>Bananas</IonSelectOption>
              </IonSelect>
            </div>
          </IonItem>
        </IonAccordion>
        <IonAccordion value='second'>
          <IonItem slot='header' color='light'>
            <IonLabel>Second Accordion</IonLabel>
          </IonItem>
          <div className='ion-padding' slot='content'>
            Second Content
          </div>
        </IonAccordion>
        <IonAccordion value='third'>
          <IonItem slot='header' color='light'>
            <IonLabel>Third Accordion</IonLabel>
          </IonItem>
          <div className='ion-padding' slot='content'>
            Third Content
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </>
  );
};

export default CategoryList;
