import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFooter,
  IonButton,
  IonList,
  IonLabel,
  IonItemDivider,
  IonItemGroup,
  IonItem,
  IonRow,
  IonGrid,
  IonCol,
  IonMenu,
  IonMenuButton,
  IonButtons,
  IonMenuToggle,
  IonModal,
  IonInput,
  IonLoading,
  useIonToast,
  IonNote,
} from "@ionic/react";
import { logOut } from "ionicons/icons";
import ExploreContainer from "../components/ExploreContainer";
import { APP_VERSION } from "../config";
import "./Tab3.css";
import useStore from "../store";
import axios from "axios";
import { useState } from "react";
import { userInfo } from "os";

interface Partner {
  nome: string;
  codiceFiscale: string;
  partitaIva: string;
  indirizzo: string;
  citta: string;
  cap: string;
  provincia: string;
  nazione: string;
  pec: string;
  sdi: string;
  telefono: string;
  mail: string;
  metodoDiPagamento: string;
}

const initialState = {
  nome: "",
  codiceFiscale: "",
  partitaIva: "",
  indirizzo: "",
  citta: "",
  cap: "",
  provincia: "",
  nazione: "",
  pec: "",
  sdi: "",
  telefono: "",
  mail: "",
  metodoDiPagamento: "",
};

const Tab3: React.FC = () => {
  const { logout, user, createVisit } = useStore();
  const [showModal, setShowModal] = useState(false);
  const [showLoadingOrder, setShowLoadingOrder] = useState(false);
  const [missingField, setMissingField] = useState(false);
  const [partner, setPartner] = useState<Partner>(initialState);
  const [present] = useIonToast();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setPartner((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    console.log(partner);
  };

  const isFieldEmpty = (fieldName: keyof Partner) => {
    return partner[fieldName].trim() === "";
  };

  function checkObjectProperties(partner: any) {
    for (const key in partner) {
      if (
        partner.hasOwnProperty(key) &&
        typeof partner[key] === "string" &&
        partner[key].trim() === ""
      ) {
        return false;
      }
    }
    return true;
  }

  function valida() {
    if (checkObjectProperties(partner)) {
      console.log("All object properties are non-empty strings");
      inviaAnagrafica();
    } else {
      console.log("At least one object property is an empty string");
      present({
        message: "Alcuni campi non sono completi",
        duration: 2500,
        position: "top",
      });
    }
  }

  const inviaAnagrafica = () => {
    setShowLoadingOrder(true);
    console.log(process.env);
    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: "Bioservice Srl",
            email: "info@bioservicesrl.eu",
          },
          to: [
            {
              email: "info@bioservicesrl.eu",
              name: `${user.name}`,
            },
          ],
          cc: [
            {
              email: `${user.username}`,
            },
          ],
          subject: "Richiesta di aggiunta anagrafica",
          htmlContent: `
          <html>
            <head></head>
            <body>
              <h1>Nuova richiesta di aggiunta anagrafica</h1>
              <b>Nuova richiesta di aggiunta anagrafica</b>
              <p>Nome/Ragione Sociale: ${partner.nome}</p>
              <p>Codice Fiscale: ${partner.codiceFiscale}</p>
              <p>Partita IVA: ${partner.partitaIva}</p>
              <p>Indirizzo: ${partner.indirizzo}</p>
              <p>Città: ${partner.citta}</p>
              <p>CAP: ${partner.cap}</p>
              <p>Provincia: ${partner.provincia}</p>
              <p>Nazione: ${partner.nazione}</p>
              <p>PEC: ${partner.pec}</p>
              <p>SDI: ${partner.sdi}</p>
              <p>Contatti:</p>
              <ul>
                <li>Telefono: ${partner.telefono}</li>
                <li>Mail: ${partner.mail}</li>
              </ul>
              <p>Agente: ${user.name}</p>
              <p>Metodo di Pagamento: ${partner.metodoDiPagamento}</p>
            </body>
          </html>
        `,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": `${process.env.REACT_APP_SENDINBLUE_KEY}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        setShowLoadingOrder(false);
        setPartner(initialState);
        setShowModal(false);
        present({
          message:
            "Anagrafica inviata con successo, riceverai una mail di conferma",
          duration: 2500,
          position: "top",
        });
      })
      .catch((error) => {
        setShowLoadingOrder(false);
        console.log(error);
        if (error.response.status === 401) {
          present({
            message: "Errore di autenticazione, controlla le credenziali",
            duration: 2500,
            position: "top",
          });
        } else {
          present({
            message: "Errore, controlla la connessione e riprova",
            duration: 2500,
            position: "top",
          });
        }
        setShowModal(false);
      });
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonMenuButton></IonMenuButton>
            </IonButtons>
            <IonTitle>Profilo</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonList>
            <IonItemGroup>
              <IonItemDivider>
                <IonLabel>Nome</IonLabel>
              </IonItemDivider>

              <IonItem>
                <IonLabel>{user.name ? user.name : "notfound"}</IonLabel>
              </IonItem>
            </IonItemGroup>

            <IonItemGroup>
              <IonItemDivider>
                <IonLabel>Email</IonLabel>
              </IonItemDivider>

              <IonItem>
                <IonLabel>
                  {user.username ? user.username : "notfound"}
                </IonLabel>
              </IonItem>
            </IonItemGroup>
            <IonItemGroup>
              <IonItemDivider>
                <IonLabel>Numero di telefono</IonLabel>
              </IonItemDivider>

              <IonItem>
                <IonLabel>
                  {user.phone_number ? user.phone_numeber : "Non presente"}
                </IonLabel>
              </IonItem>
            </IonItemGroup>
            <IonItemGroup>
              <IonItemDivider>
                <IonLabel>Versione</IonLabel>
              </IonItemDivider>

              <IonItem>
                <IonLabel>
                  {APP_VERSION ? APP_VERSION : "Non presente"}
                </IonLabel>
              </IonItem>
            </IonItemGroup>
          </IonList>

          <IonItem color='warning'>
            <IonLabel class='ion-text-wrap'>
              In caso di emergenza è possibile effettuare l'ordine via whatsapp
              o email
            </IonLabel>
          </IonItem>
          <IonGrid>
            <IonRow>
              <IonCol size='6'>
                <IonButton
                  color='tertiary'
                  expand='block'
                  href='https://wa.me/+393292737736?text=Buongiorno+L%27app+non+funziona+vorrei+effettuare+il+seguente+ordine%3A'
                >
                  Whatsapp
                </IonButton>
              </IonCol>
              <IonCol size='6'>
                <IonButton
                  color='tertiary'
                  expand='block'
                  href='mailto:info@bioservicersl.eu?subject=ordine&body=Buongiorno vorrei effettuare il seguente ordine'
                >
                  Email
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            color='tertiary'
            expand='block'
            href='https://Bioservicesrl.it'
          >
            Sito Web
          </IonButton>
          <IonButton expand='block' onClick={() => setShowModal(true)}>
            Agg. Nuova Anagrafica
          </IonButton>
          <IonButton expand='block' onClick={() => logout()}>
            Effettua il logout
          </IonButton>
        </IonContent>
      </IonPage>
      <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='end'>
              <IonButton onClick={() => setShowModal(false)}>
                indietro
              </IonButton>
            </IonButtons>
            <IonTitle>Aggiunta Anagrafica</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem>
            <IonLabel position='floating'>Nome/Ragione Sociale</IonLabel>
            <IonInput
              type='text'
              placeholder='Inserisci il nome o la ragione sociale'
              name='nome'
              value={partner.nome}
              onIonChange={handleChange}
            />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position='floating'>Codice Fiscale</IonLabel>
            <IonInput
              type='text'
              placeholder='Scrivi il Codice Fiscale'
              name='codiceFiscale'
              value={partner.codiceFiscale}
              onIonChange={handleChange}
            />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position='floating'>Partita IVA (solo numeri)</IonLabel>
            <IonInput
              type='text'
              placeholder='Scrivi Partita IVA'
              name='partitaIva'
              value={partner.partitaIva}
              onIonChange={handleChange}
            />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position='floating'>Indirizzo</IonLabel>
            <IonInput
              type='text'
              placeholder='Scrivi Indirizzo'
              name='indirizzo'
              value={partner.indirizzo}
              onIonChange={handleChange}
            />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position='floating'>Città</IonLabel>
            <IonInput
              type='text'
              placeholder='Scrivi Città'
              name='citta'
              value={partner.citta}
              onIonChange={handleChange}
            />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position='floating'>CAP</IonLabel>
            <IonInput
              type='text'
              placeholder='Scrivi CAP'
              name='cap'
              value={partner.cap}
              onIonChange={handleChange}
            />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position='floating'>Provincia</IonLabel>
            <IonInput
              type='text'
              placeholder='Scrivi Provincia'
              name='provincia'
              value={partner.provincia}
              onIonChange={handleChange}
            />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position='floating'>Nazione</IonLabel>
            <IonInput
              type='text'
              placeholder='Scrivi Nazione'
              name='nazione'
              value={partner.nazione}
              onIonChange={handleChange}
            />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position='floating'>PEC</IonLabel>
            <IonInput
              type='text'
              placeholder='ScriviPEC'
              name='pec'
              value={partner.pec}
              onIonChange={handleChange}
            />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position='floating'>SDI</IonLabel>
            <IonInput
              type='text'
              placeholder='Scrivi SDI'
              name='sdi'
              value={partner.sdi}
              onIonChange={handleChange}
            />
          </IonItem>{" "}
          <IonItem>
            <IonLabel position='floating'>Telefono</IonLabel>
            <IonInput
              type='tel'
              placeholder='Scrivi Telefono'
              name='telefono'
              value={partner.telefono}
              onIonChange={handleChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>E-mail</IonLabel>
            <IonInput
              type='email'
              placeholder='Scrivi Mail'
              name='mail'
              value={partner.mail}
              onIonChange={handleChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>
              Metodo di pagamento preferito
            </IonLabel>
            <IonInput
              type='text'
              placeholder='Scrivi metodo di pagamento'
              name='metodoDiPagamento'
              value={partner.metodoDiPagamento}
              onIonChange={handleChange}
            />
          </IonItem>
        </IonContent>
        <IonFooter>
        <IonToolbar>
        <IonButton expand='block' fill='outline' onClick={() => valida()}>
          Invia Anagrafica
        </IonButton>
        </IonToolbar>
      </IonFooter>
      </IonModal>
      <IonLoading
        isOpen={showLoadingOrder}
        message={"Perfavore attendi, sto inviando l ordine..."}
      />
    </>
  );
};

export default Tab3;
