import React, { useRef } from "react";
import {
  IonButton,
  IonModal,
  IonHeader,
  IonFooter,
  IonContent,
  IonToolbar,
  IonTitle,
  IonAlert,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonSelect,
  IonInput,
  IonSelectOption,
  useIonToast,
  IonIcon,
  IonChip,
  IonSegmentButton,
  IonSegment,
  IonItemGroup,
  IonItemDivider,
  IonLoading,
  IonToggle,
  IonAccordion,
  IonAccordionGroup,
  IonButtons,
  IonTextarea,
} from "@ionic/react";
import { useEffect, useState } from "react";

import "./Tab2.css";
import axios from "axios";
import useStore from "../store";
import { cart } from "ionicons/icons";
import IndirizziSpedione from "../components/IndirizziSpedione";
import ModalExample from "../components/ModalExample";
import CategoryList from "../components/CategoryList";
import { Virtuoso } from "react-virtuoso";
import Fuse from "fuse.js";
import Lottie from "react-lottie-player";
import lottieJson from "../lotties/Confirmed_tick.json";
import Error from "../lotties/Error.json";

interface ProductLine {
  id: string;
  quantity: number;
  name: string | null;
  default_code: string | null;
  qty_available: number;
}

interface State {
  fatt_id: null | number;
  sped_id: null | number;
  priceList_id: null | number;
  payTerm_id: null | number;
  tag_id: string;
  order_lines_odoo: any[];
  product_lines: ProductLine[];
  fatt_name: string | null;
  fatt_city: string | null;
  fatt_zip: string | null;
  fatt_street: string | null;
  child_ids: any[];
  sped_name: string | null;
  sped_city: string | null;
  sped_zip: string | null;
  sped_street: string | null;
  order_note: string | null;
}

const initialState: State = {
  fatt_id: null,
  sped_id: null,
  priceList_id: null,
  payTerm_id: null,
  tag_id: "",
  order_lines_odoo: [],
  product_lines: [
    {
      id: "test",
      quantity: 0,
      name: null,
      default_code: null,
      qty_available: 0,
    },
  ],
  fatt_name: null,
  fatt_city: null,
  fatt_zip: null,
  fatt_street: null,
  child_ids: [],
  sped_name: null,
  sped_city: null,
  sped_zip: null,
  sped_street: null,
  order_note: null,
};

const Tab2: React.FC = () => {
  // Destructure state and dispatch functions from the global store
  const {
    products,
    fetchProducts,
    fetchOrders,
    fetchPartners,
    partners,
    categories,
    fetchCategories,
    sendOrderConfirm,
    updatePrice,
    user,
  } = useStore();

  // State variables to manage modal visibility
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showModalOrderStatus, setShowModalOrderStatus] = useState(false);
  const [showModalOrderFail, setShowModalOrderFail] = useState(false);
  const [showModalAddress, setShowModalAddress] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [showModal_riepilogo, setShowModal_riepilogo] = useState(false);

  // State variable for toast message
  const [present] = useIonToast();
  const filteredPartners = partners.filter((d: any) => d.parent_id === false);
  // Filtered partner results
  let [results, setResults] = useState([...filteredPartners]);

  // Filtered product results
  const [prodotti, setProdotti] = useState<any[]>([]);
  const [categorie, setCategorie] = useState<any[]>([]);
  let [productResults, setProductResults] = useState([...prodotti]);

  // State variables to manage loading spinner
  const [showLoadingOrder, setShowLoadingOrder] = useState(false);
  const [showLoadingfetch, setShowLoadingFetch] = useState(false);

  // State variable for priority toggle
  const [isPriorita, setIsPriorita] = useState(false);

  // State variable to manage order form data
  const [ordine, setOrdine] = useState(initialState);

  // Ref for modal2
  const modal2 = useRef<HTMLIonModalElement>(null);

  // Disable order submit button if required fields are not filled
  const isDisabled =
    !ordine.fatt_id || !ordine.sped_id || ordine.product_lines.length < 1;

  const filteredList = partners.filter((item: any) =>
    ordine.child_ids.includes(item.id)
  );
  let [resultsAddress, setResultsAddress] = useState<any[]>([...filteredList]);

  // Fetch products on component mount
  useEffect(() => {
    //setShowLoadingFetch(true);
    const token = localStorage.getItem("jwt");
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    /*fetchProducts().then(() => {
      setShowLoadingFetch(false);
    });
    */
    setProdotti(products);
    /*fetchCategories().then(() => {
      setShowLoadingFetch(false);
    });*/
    setCategorie(categories);
    console.log("", prodotti);

    // Remove "test" from product lines on mount
    setOrdine({
      ...ordine,
      product_lines: ordine.product_lines.filter(
        (product) => product.id !== "test"
      ),
    });

    // Show welcome back toast message on mount
  }, []);

  // Fetch partners on component mount
  /* useEffect(() => {
    setShowLoadingFetch(true);
    fetchPartners().then(() => {
      setShowLoadingFetch(false);
    });
  }, []);*/

  // Use effect hook to fetch the partners data
  useEffect(() => {
    // Fetch the data when the component is mounted
    fetchPartners();
  }, []);

  // Handle the search input change and filter the partners
  const handleChange = (ev: Event) => {
    const fuse = new Fuse(filteredPartners, {
      keys: ["name"],
      threshold: 0.3,
    });
    const target = ev.target as HTMLIonSearchbarElement;
    const query = target.value?.trim().toLowerCase() || "";

    if (query === "") {
      setResults([...filteredPartners]);
    } else {
      const searchResults = fuse.search(query);
      const items = searchResults.map((r) => r.item);
      setResults(items);
    }
  };

  // Limit the number of search results to 20
  const limitedResults = results.slice(0, 20);

  // Handle the search input change and filter the products
  const handleProductSelection = (ev: Event) => {
    const fuse = new Fuse(products, {
      keys: ["default_code", "name"],
      threshold: 0.3,
    });
    let query = "";
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();
    const results = fuse.search(query);
    setProductResults(results);
  };

  // Select the client and update the order details
  const selectClient = (item: any) => {
    setOrdine({
      ...ordine,
      priceList_id: item.property_product_pricelist[0],
      payTerm_id: item.property_payment_term_id[0], //probabile causa di errore
      fatt_id: item.id,
      fatt_city: item.city,
      fatt_zip: item.zip,
      fatt_name: item.name,
      fatt_street: item.street,
      child_ids: item.child_ids,
      sped_id: item.id,
      sped_city: item.city,
      sped_zip: item.zip,
      sped_name: item.name,
      sped_street: item.street,
    });
    setShowModal1(false);
    setResults([...filteredPartners]);
  };

  const handleOrderNote = (ev: Event) => {
    const target = ev.target as HTMLInputElement;
    setOrdine({
      ...ordine,
      order_note: target.value,
    });

    console.log("testtttttt", ordine);
  };

  const handleChangeAddress = (ev: Event) => {
    let query = "";
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();
    setResultsAddress(
      filteredList.filter((d: any) => d.name.toLowerCase().indexOf(query) > -1)
    );
  };

  //genera le order lines da invisre alle api per gli ordini
  const generateOrderLines = (prodotti: any[]) => {
    let orderLines = "";
    for (let i = 0; i < prodotti.length; i++) {
      const product = prodotti[i];
      orderLines += `(0, 0, {'product_id': ${product.id}, 'product_uom_qty': ${product.quantity}})`;
      if (i < prodotti.length - 1) {
        orderLines += ",";
      }
    }
    return `[${orderLines}]`;
  };
  //array di prova
  const orderLines = generateOrderLines(ordine.product_lines);
  // Output: 'order_line': [(0, 0, {'product_id': 30, 'product_uom_qty': 2}),(0, 0, {'product_id': 35, 'product_uom_qty': 3}),(0, 0, {'product_id': 40, 'product_uom_qty': 1})]

  const removeProduct = (id: any) => {
    setOrdine({
      ...ordine,
      product_lines: ordine.product_lines.filter(
        (product) => product.id !== id
      ),
    });
  };

  function addProduct(product: any) {
    const existingProduct = ordine.product_lines.find(
      (p) => p.id === product.id
    );
    if (existingProduct) {
      setOrdine({
        ...ordine,
        product_lines: ordine.product_lines.map((p) =>
          p.id === product.id
            ? { ...p, quantity: p.quantity + product.quantity }
            : p
        ),
      });
    } else {
      setOrdine({
        ...ordine,
        product_lines: [...ordine.product_lines, product],
      });
    }
  }

  const incrementQuantity = (id: any) => {
    setOrdine({
      ...ordine,
      product_lines: ordine.product_lines.map((product) =>
        product.id === id
          ? { ...product, quantity: product.quantity + 1 }
          : product
      ),
    });
  };

  const decrementQuantity = (id: any) => {
    setOrdine({
      ...ordine,
      product_lines: ordine.product_lines.map((product) =>
        product.id === id
          ? { ...product, quantity: Math.max(product.quantity - 1, 1) }
          : product
      ),
    });
  };

  const presentToast = (position: "top" | "middle" | "bottom") => {
    present({
      message: "Prodotto Aggiunto!",
      duration: 200,
      position: position,
    });
  };

  //Acual quantity in the cart
  const printQuantity = (id: any) => {
    const product = ordine.product_lines.find((product) => product.id === id);
    if (product && product.quantity > 0) {
      return product.quantity;
    } else {
      return false;
    }
  };
  //print out a ordel line lije this, needed for correct order generation by odoo
  //[(0, 0, { 'product_id': 14596, 'product_uom_qty': 2 })]

  //********************************************/
  //********** sending the order ************** */
  //*******************************************/

  const sendOrder = () => {
    setShowLoadingOrder(true);
    setOrdine({
      ...ordine,
      product_lines: ordine.product_lines.filter(
        (product) => product.id !== "test"
      ),
    });
    return axios
      .post("/create/sale.order", {
        values: `{
        "partner_id": ${ordine.fatt_id},
        "partner_shipping_id": ${ordine.sped_id},
        "partner_invoice_id": ${ordine.fatt_id},
        "order_line": ${generateOrderLines(ordine.product_lines)},
        "tag_ids":${isPriorita ? "[1, 6]" : "[6]"},
        ${ordine.order_note ? `"note_da_app":"${ordine.order_note}",` : ""}
        ${ordine.priceList_id ? `"pricelist_id":${ordine.priceList_id},` : ""}
        ${ordine.payTerm_id ? `"payment_term_id":${ordine.payTerm_id},` : ""}
      }`,
      })
      .then((response) => {
        console.log("id dell'ordine appena creato", response.data[0]);
        setShowLoadingOrder(false);
        updatePrice(response.data[0]);
        setOrdine(initialState);
        setIsPriorita(false);
        present({
          message: "Ordine Inviato con successo!",
          duration: 1300,
          position: "top",
        });
        setShowModal_riepilogo(false);
        setShowModalOrderStatus(true);
        // sendOrderConfirm(user.username, user.name, ordine.fatt_name, ordine);
        fetchOrders();
      })
      .catch((error) => {
        setShowModalOrderFail(true);
        present({
          message:
            "Ordine non inviato, riprovare, se l'errore persiste contattare Bioservice",
          duration: 2500,
          position: "top",
        });
        setIsPriorita(false);
        setShowLoadingOrder(false);
        console.log(error);
      });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Ordini</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonButton
          expand='block'
          disabled={partners.length === 0}
          onClick={() => setShowModal1(true)}
          style={{ whiteSpace: 'normal' }}
        >
          Seleziona il cliente
        </IonButton>

        {ordine.fatt_id ? (
          <>
            <IonItem>
              <IonLabel>
                <h2 style={{ whiteSpace: 'normal' }}>
                  <strong>indirizzo di fatturazione:</strong>
                </h2>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h2 style={{ whiteSpace: 'normal' }}>{ordine.fatt_name}</h2>
                <p style={{ whiteSpace: 'normal' }}>
                  {ordine.fatt_street}, {ordine.fatt_city}, {ordine.fatt_zip}
                </p>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h2 style={{ whiteSpace: 'normal' }}>
                  <strong>indirizzo di spedizione:</strong>
                </h2>
              </IonLabel>
            </IonItem>

            {filteredList.length > 0 ? (
              <>
                <IonButton
                  expand='block'
                  color='secondary'
                  disabled={products.length === 0}
                  onClick={() => {
                    setShowModalAddress(true);
                    setResultsAddress(filteredList.slice(0, 20));
                  }}
                >
                  Scegli uno indirizzo di consegna
                </IonButton>

                <IonItem>
                  <IonLabel>
                    <h2 style={{ whiteSpace: 'normal' }}>{ordine.sped_name}</h2>
                    <p style={{ whiteSpace: 'normal' }}>
                      {ordine.sped_street}, {ordine.sped_city}
                    </p>
                  </IonLabel>
                </IonItem>
              </>
            ) : (
              <IonItem>
                <IonLabel>
                  <h2>{ordine.fatt_name}</h2>
                  <p>
                    {ordine.fatt_street}, {ordine.fatt_city}
                  </p>
                </IonLabel>
              </IonItem>
            )}

            <IonItem color='warning'>
              <IonLabel class='ion-text-wrap'>
                {filteredList.length === 0 && (
                  <>
                    Nessun Indirizzo di spedizione trovato, verrà usato <br />
                    quello di fatturazione
                  </>
                )}
                {filteredList.length > 0 &&
                  " ATTENZIONE: Se non si seleziona un indirizzo verrà usato quello di fatturazione"}
              </IonLabel>
            </IonItem>
          </>
        ) : (
          <IonItem>
            <IonLabel>
              <p>Seleziona un cliente</p>
            </IonLabel>
          </IonItem>
        )}

        <IonButton
          expand='block'
          disabled={products.length === 0}
          onClick={() => setShowModal2(true)}
          style={{ whiteSpace: 'normal' }}
        >
          seleziona i prodotti per codice
        </IonButton>
        <IonList>
          {ordine.product_lines.map((product) => {
            if (product.quantity > 0) {
              return (
                <IonItem>
                  <IonLabel>
                    <h2>{product.default_code}</h2>
                    {product.qty_available > 10 ? (
                      <p style={{ color: "green" }}>In Stok</p>
                    ) : product.qty_available > 0 ? (
                      <p style={{ color: "orange" }}>Disponibilita limitata</p>
                    ) : (
                      <p style={{ color: "red" }}>Esaurito</p>
                    )}
                  </IonLabel>
                  <IonLabel slot='end' color='warning'>
                    <IonButton
                      shape='round'
                      onClick={() => decrementQuantity(product.id)}
                    >
                      -
                    </IonButton>
                    <IonButton fill='clear'>
                      {printQuantity(product.id)}
                    </IonButton>
                    <IonButton
                      shape='round'
                      onClick={() => incrementQuantity(product.id)}
                    >
                      +
                    </IonButton>
                    <IonButton
                      color='danger'
                      shape='round'
                      onClick={() => removeProduct(product.id)}
                    >
                      X
                    </IonButton>
                  </IonLabel>
                </IonItem>
              );
            } else {
              return null;
            }
          })}
        </IonList>
      </IonContent>
      {/************************************************/}
      {/*modal containing the parnetrs filtered results */}
      {/************************************************/}
      <IonModal isOpen={showModal1} onDidDismiss={() => setShowModal1(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton onClick={() => setShowModal1(false)}>
                Indietro
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonSearchbar
          animated={true}
          onIonChange={(ev) => handleChange(ev)}
        ></IonSearchbar>
        <IonContent>
          <Virtuoso
            style={{ height: "100%" }}
            data={limitedResults}
            itemContent={(index, limitedResults) => (
              <IonItem
                key={limitedResults.id}
                onClick={() => {
                  selectClient(limitedResults);
                }}
              >
                <IonLabel>
                  <h2 style={{ whiteSpace: 'normal' }}>{limitedResults.name}</h2>
                  <p style={{ whiteSpace: 'normal' }}>
                    {limitedResults.street}, {limitedResults.city} ,{" "}
                    {limitedResults.zip}
                  </p>
                </IonLabel>
              </IonItem>
            )}
          />
        </IonContent>

        <IonFooter>
          <IonToolbar>
            <IonButton
              expand='block'
              fill='outline'
              onClick={() => setShowModal1(false)}
            >
              Chiudi
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
      {/************************************************/}
      {/*modal containing the product filtered results */}
      {/************************************************/}
      <IonModal
        isOpen={showModal2}
        ref={modal2}
        onDidDismiss={() => setShowModal2(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton onClick={() => setShowModal2(false)}>
                Indietro
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSearchbar
            onClick={() => modal2.current?.setCurrentBreakpoint(0.75)}
            onIonChange={(ev) => handleProductSelection(ev)}
            placeholder='Inizia a scrivere un codice prodotto'
          ></IonSearchbar>
          <Virtuoso
            style={{ height: "100%" }}
            data={productResults.filter(
              (product) => product.item.sale_ok === true
            )}
            itemContent={(index, product) => (
              <IonItem
                key={product.item.id}
                onClick={() => {
                  addProduct({
                    id: product.item.id,
                    quantity: 1,
                    name: product.item.name,
                    default_code: product.item.default_code,
                    qty_available: product.item.qty_available,
                  });
                  presentToast("top");
                  console.log("oedine", ordine);
                }}
              >
                <IonLabel>
                  <h2 style={{ whiteSpace: 'normal' }} >{product.item.default_code}</h2>
                  <p style={{ whiteSpace: 'normal' }}>{product.item.name}</p>
                  {product.item.qty_available > 10 ? (
                    <p style={{ color: "green" }}>in stok</p>
                  ) : product.item.qty_available > 0 ? (
                    <p style={{ color: "orange" }}>Disponibilita limitata</p>
                  ) : (
                    <p style={{ color: "red" }}>Attualmente esaurito</p>
                  )}
                </IonLabel>
                <IonLabel slot='end' color='warning'>
                  {printQuantity(product.item.id)}
                </IonLabel>
                <IonIcon icon={cart} slot='end'></IonIcon>
              </IonItem>
            )}
          />
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButton
              expand='block'
              fill='outline'
              onClick={() => setShowModal2(false)}
            >
              Chiudi
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
      <IonButton
        expand='block'
        disabled={isDisabled}
        style={{ whiteSpace: 'normal' }}
        onClick={() => {
          setShowModal_riepilogo(true);
          setOrdine({
            ...ordine,
            product_lines: ordine.product_lines.filter(
              (product) => product.id !== "test"
            ),
          });
        }}
      >
        Concludi l'ordine
      </IonButton>
      <IonModal
        isOpen={showModal_riepilogo}
        onDidDismiss={() => setShowModal_riepilogo(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton onClick={() => setShowModal_riepilogo(false)}>
                Indietro
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Note Aggiuntive</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonInput
                onIonChange={(ev) => handleOrderNote(ev)}
                placeholder='Scrivi qui la tua nota'
              ></IonInput>
            </IonItem>
          </IonItemGroup>
          <IonItemGroup>
            <IonItemDivider color='warning'>
              <IonLabel style={{ whiteSpace: 'normal' }}>Ordine ad Alta Priorità</IonLabel>
              <IonToggle
                checked={isPriorita}
                onIonChange={() => setIsPriorita(!isPriorita)}
                slot='end'
              ></IonToggle>
            </IonItemDivider>
          </IonItemGroup>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Ordine Fatturato a:</IonLabel>
            </IonItemDivider>

            <IonItem>
              <IonLabel style={{ whiteSpace: 'normal' }}>{ordine.fatt_name}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel style={{ whiteSpace: 'normal' }}>
                {ordine.fatt_street}, {ordine.fatt_city}
              </IonLabel>
            </IonItem>
          </IonItemGroup>

          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Verra Spedito a:</IonLabel>
            </IonItemDivider>

            <IonItem>
              <IonLabel style={{ whiteSpace: 'normal' }}>{ordine.sped_name}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel style={{ whiteSpace: 'normal' }}>
                {ordine.sped_street}, {ordine.sped_city}
              </IonLabel>
            </IonItem>
          </IonItemGroup>

          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>I prodotti ordinati sono:</IonLabel>
            </IonItemDivider>
            {ordine.product_lines.map((item) => (
              <IonItem key={item.id}>
                <IonLabel style={{ whiteSpace: 'normal' }}>{item.default_code}</IonLabel>
                <IonLabel style={{ whiteSpace: 'normal' }} slot='end' color='warning'>
                  {printQuantity(item.id)}
                </IonLabel>
                <IonIcon icon={cart} slot='end'></IonIcon>
              </IonItem>
            ))}
          </IonItemGroup>

          <IonLoading
            isOpen={showLoadingOrder}
            message={"Perfavore attendi, sto inviando l ordine..."}
          />
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButton expand='block' onClick={() => setShowAlert(true)}>
              Invia
            </IonButton>
            <IonButton
              expand='block'
              onClick={() => setShowModal_riepilogo(false)}
            >
              indietro
            </IonButton>
          </IonToolbar>
        </IonFooter>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header="Conferma l'ordine"
          message="Hai controllato l'Alta Priorita?"
          buttons={[
            {
              text: "NO Annulla",
              role: "cancel",
              handler: () => {
                console.log("Order sending canceled");
              },
            },
            {
              text: "OK Invia ",
              handler: () => {
                console.log("Order sent");
                sendOrder();
              },
            },
          ]}
        />
      </IonModal>
      <IonLoading
        isOpen={showLoadingfetch}
        message={"Perfavore attendi, sto aggiornado i dati..."}
      />
      <IonModal isOpen={showModal3} onDidDismiss={() => setShowModal3(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton onClick={() => setShowModal3(false)}>Chiudi</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSegment value='default'>
            <IonSegmentButton value='Impianti'>
              <IonLabel>Impianti</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value='Componentistica'>
              <IonLabel>Componentistica</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value='strumentario'>
              <IonLabel>strumentario</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <CategoryList
            categories={categories}
            onSelectCategory={(categoryId: any) =>
              setSelectedCategoryId(categoryId)
            }
          />
        </IonContent>
      </IonModal>
      {/************************************************/}
      {/*modal containing the addresses filtered results */}
      {/************************************************/}
      <IonModal
        backdrop-dismiss={false}
        isOpen={showModalAddress}
        onDidDismiss={() => setShowModalAddress(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot='start'>
              <IonButton onClick={() => setShowModalAddress(false)}>
                Indietro
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSearchbar
            onIonChange={(ev) => handleChangeAddress(ev)}
          ></IonSearchbar>
          <Virtuoso
            data={resultsAddress}
            itemContent={(index, r) => (
              <IonItem
                onClick={() => {
                  setOrdine({
                    ...ordine,
                    sped_id: r.id,
                    sped_name: r.name,
                    sped_city: r.city,
                    sped_street: r.street,
                  });
                  setShowModalAddress(false);
                }}
              >
                <IonLabel>
                  <h2 style={{ whiteSpace: 'normal' }}>{r.name}</h2>
                  <p style={{ whiteSpace: 'normal' }}>
                    {r.street}, {r.city} , {r.zip}
                  </p>
                </IonLabel>
              </IonItem>
            )}
          />
        </IonContent>
        {/******************** SUCCES MODAL *****************************/}
      </IonModal>
      <IonModal
        isOpen={showModalOrderStatus}
        onDidDismiss={() => setShowModalOrderStatus(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Stato dell'ordine </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding'>
          <div>
            <Lottie
              loop
              animationData={lottieJson}
              play
              style={{
                margin: "auto",
                maxWidth: "70%",
                maxHeight: "70%",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </div>

          <h1 style={{ marginTop: "20px" }}>Ordine inviato con successo</h1>
          <p>
            Nota Bene: ricordati che per essere assolutamente CERTO dell'invio
            del ordine dev controllare che sia visibile nello stroico ordini, se
            non è presente l'ordine NON è stato recapitato{" "}
          </p>
        </IonContent>
        <IonButton
          expand='block'
          size='large'
          color='warning'
          onClick={() => setShowModalOrderStatus(false)}
        >
          Ho Letto
        </IonButton>
      </IonModal>
      {/******************** FAIL MODAL *****************************/}
      <IonModal
        isOpen={showModalOrderFail}
        onDidDismiss={() => setShowModalOrderFail(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>Stato dell'ordine </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className='ion-padding'>
          <div>
            <Lottie
              loop
              animationData={Error}
              play
              style={{
                margin: "auto",
                maxWidth: "70%",
                maxHeight: "70%",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </div>

          <h1 style={{ marginTop: "20px" }}>
            ATTENZIONE, ERRORE, l'ORDINE NON E' ANDATO A BUON FINE
          </h1>
          <h2>
            {" "}
            Le cause possono essere molteplici, controlla la connessione e prova
            a:{" "}
          </h2>
          <ul>
            <li>Riprovare a fare l'ordine</li>
            <li>Chiudere e riaprire l'app</li>
          </ul>
          <p>
            Se l'errore persiste contatta bioservice,ed effettua un ordine
            telefonico.{" "}
          </p>
        </IonContent>
        <IonButton
          expand='block'
          size='large'
          color='warning'
          onClick={() => setShowModalOrderFail(false)}
        >
          Ho Letto
        </IonButton>
      </IonModal>
    </IonPage>
  );
};

export default Tab2;
