import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonList,
  IonTextarea,
  IonLabel,
  IonItemDivider,
  IonItemGroup,
  IonItem,
  IonRow,
  IonGrid,
  IonCol,
  IonMenu,
  IonMenuButton,
  IonButtons,
  IonMenuToggle,
  IonModal,
  IonPopover,
  IonInput,
  IonLoading,
  useIonToast,
  IonNote,
  IonSearchbar,
  IonFabButton,
  IonSegmentButton,
  IonSegment,
  IonDatetime,
  IonIcon,
  IonFab,
  IonFabList,
  IonDatetimeButton,
} from "@ionic/react";
import { ellipsisHorizontalOutline } from "ionicons/icons";
import ExploreContainer from "../components/ExploreContainer";
import { APP_VERSION } from "../config";
import useStore from "../store";
import axios from "axios";
import { useState, useEffect } from "react";
import { Virtuoso } from "react-virtuoso";
import { Redirect, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";

interface Props {
  match: {
    params: {
      id: string;
    };
  };
}

const initialstate = {
  oggetto: "Visita al Cliente",
  data: "",
  note: "",
};

const AddVisit: React.FC = ({ match }: any) => {
  const { user, partners, createVisit, fetchMeetings, fetchPartners } =
    useStore();
  const [showModalDate, setShowModalDate] = useState(false);
  const [present] = useIonToast();
  const [showLoadingOrder, setShowLoadingOrder] = useState(false);
  const [date, setDate] = useState("");
  const [visit, setVisit] = useState({
    oggetto: "Visita al Cliente",
    data: "",
    note: "",
  });
  const history = useHistory();

  const cliente = partners.find(
    (partner: { id: string }) => String(partner.id) === match.params.id
  );
  const presentToast = (position: "top" | "middle" | "bottom") => {
    present({
      message: "Prodotto Aggiunto!",
      duration: 200,
      position: position,
    });
  };

  useEffect(() => {
    const today = new Date();
    const formattedDate = formatDate(today);

    setVisit({ ...visit, data: formattedDate });
  }, []);

  function formatDate(date: Date): string {
    const year = date.getFullYear().toString().padStart(4, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const valida = () => {
    if (visit.oggetto.trim() === "" || visit.data.trim() === "") {
      console.log("Riempi tutti i campi");
      return;
    }
    console.log("Invio dati al server...");
    setShowLoadingOrder(true);
    createVisit(match.params.id, user.partner_id, visit).then(() => {
      setShowLoadingOrder(false);
      fetchPartners();
      setVisit(initialstate);
      history.push("/clienti");
      present({
        message: "Visita aggiunta con successo",
        duration: 1300,
        position: "top",
      });
    });
  };

  const handleDateChange = (event: any) => {
    setVisit({ ...visit, data: event.detail.value! });
    console.log(visit);
  };
  const handleTextChange = (event: any) => {
    setVisit({ ...visit, note: event.detail.value! });
    console.log(visit);
  };

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Cliente n°{match.params.id}</IonTitle>
            <IonButtons slot='start'>
              <IonButton routerLink='/clienti' routerDirection='back'>
                Indietro
              </IonButton>
            </IonButtons>
            <IonButtons slot='end'>
              <IonButton>
                <IonIcon icon={ellipsisHorizontalOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>Cliente:</IonLabel>
            </IonItemDivider>
            <IonItem lines='full'>
              <IonLabel>
                <h2>{cliente?.name}</h2>
                <p>
                  {cliente?.street}, {cliente?.city} , {cliente?.zip}
                </p>
              </IonLabel>
            </IonItem>
          </IonItemGroup>
          <IonItemDivider>
            <IonLabel>Informazioni Visita:</IonLabel>
          </IonItemDivider>
          <IonItem onClick={() => setShowModalDate(true)}>
            <IonLabel>Data</IonLabel>
            <IonDatetimeButton datetime='datetime'></IonDatetimeButton>
          </IonItem>
          <IonItem>
            <IonLabel position='floating'>Note Aggiuntive</IonLabel>
            <IonTextarea
              placeholder='Descrivi come è andata la visita'
              autoGrow={true}
              onIonChange={handleTextChange}
              value={visit.note}
            ></IonTextarea>
          </IonItem>
        </IonContent>
        <IonButton expand='block' fill='outline' onClick={() => valida()}>
          Aggiungi visita
        </IonButton>
      </IonPage>
      <IonLoading
        isOpen={showLoadingOrder}
        message={"Perfavore attendi, sto inviando l ordine..."}
      />
      <IonModal keepContentsMounted={true}>
        <IonDatetime
          presentation='date'
          value={visit.data}
          onIonChange={handleDateChange}
          showDefaultButtons={true}
          id='datetime'
        ></IonDatetime>
      </IonModal>
    </>
  );
};

export default AddVisit;
